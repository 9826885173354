<template>
    <n-config-provider :theme-overrides="themeOverrides" :breakpoints="{xs:0,s:576,m:768,l:992,xl:1200,xxl:1500}">
<!--        <NThemeEditor>-->
        <n-modal-provider>
            <n-message-provider placement="bottom-right">
                <header-vue v-if="route.name!=='account'"/>
                <router-view :key="$route.fullPath"></router-view>
                <footer-vue v-if="route.name!=='account'"/>
                <cookie/>
            </n-message-provider>
        </n-modal-provider>
<!--        </NThemeEditor>-->
    </n-config-provider>
</template>

<script setup>
import {NThemeEditor,NConfigProvider,NMessageProvider,NModalProvider} from 'naive-ui'
import {useStore} from '@/store'
import headerVue from '@/components/vheader.vue'
import footerVue from '@/components/vfooter.vue'
import cookie from '@/components/cookie.vue'
import {useRoute} from "vue-router";

const route = useRoute();
const themeOverrides = {
    common:{

    },
    Input:{
        placeholderColor:'rgba(23, 45, 62, 0.6)',
        fontSizeMedium: '13px',
        heightMedium: '44px',
        caretColor:'rgba(23, 45, 62, 0.6)'
    },
    Alert:{
        colorError:'#db3737',
        contentTextColorError: '#fff',
        closeIconColorError:'rgba(255,255,255,.7)',
        closeIconColorHoverError:'rgba(255,255,255,.7)',
        borderError:''
    },
    Button:{
        rippleColor:'rgba(255,255,255,0)',
        rippleColorPrimary:'rgba(255,255,255,0)',
        rippleColorInfo:'rgba(255,255,255,0)',
        colorPrimary: 'rgb(211, 178, 91)',
        colorHoverPrimary: 'rgba(211, 178, 91, 0.8)',
        colorPressedPrimary:'rgb(211, 178, 91)',
        colorFocusPrimary:'rgba(211, 178, 91, 0.8)',
        colorDisabledPrimary: 'rgba(211, 178, 91, 0.9)',
        colorInfo: 'rgb(23, 45, 62)',
        colorHoverInfo: 'rgba(23, 45, 62, 0.8)',
        colorPressedInfo:'rgb(23, 45, 62)',
        colorFocusInfo:'rgba(23, 45, 62, 0.8)',
        colorDisabledInfo: 'rgba(23, 45, 62, 0.9)',
        textColorInfo: '#fff',
        textColor: 'rgba(211, 178, 91, 0.8)',
        textColorHover: 'rgba(211, 178, 91, 0.6)',
        textColorPressed: 'rgba(211, 178, 91, 0.8)',
        textColorFocus: 'rgba(211, 178, 91, 0.8)',
        border: '0.5px solid rgb(211, 178, 91)',
        borderHover: '0.5px solid rgba(211, 178, 91, 0.8)',
        borderFocus: '0.5px solid rgba(211, 178, 91, 0.8)',
        borderPressed: '0.5px solid rgba(211, 178, 91, 0.8)',
        heightMedium: '44px',
        paddingRoundMedium: '0 34px',
        fontSizeMedium:'15px',
        fontWeightStrong: '700'
    }
}
</script>
