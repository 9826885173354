import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: ()=>import('../views/Home.vue')
        },
        {
            path: '/faq',
            name: 'faq',
            component: ()=>import('../views/Faq.vue')
        },
        {
            path: '/account',
            name: 'account',
            component: ()=>import('../views/Account.vue')
        }
    ]
})

export default router