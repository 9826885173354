<template>
    <div class="cookie" v-if="show">
        <div class="cookie-content">
            <div>
                <div class="c-c-top">
                    <p class="c-head">This pop-up appears when you visit the site for the first time and informs you on the use of cookies. You have the options to:</p>
                    <div class="c-list">
                        <div>Accept all cookies being stored on your device.</div>
                        <div>Refuse all cookies, except the essential ones, being stored on your device.</div>
                        <div>Accept only certain cookies categories.</div>
                        <div>View and read the Cookies Policy. <a href="https://tether.to/en/legal#cookies-policy" target="_blank">Read more</a></div>
                    </div>
                    <div class="c-btns">
                        <div>
                            <n-button class="c-btn" type="info" round :bordered="false" @click="setCookie">Accept</n-button>
                            <n-button @click="setCookie" class="c-btn" round :bordered="false">Decline</n-button>
                        </div>
                        <div><n-button class="c-btn" @click="showBt=!showBt" round>Settings <arrow-icon class="c-arrow-icon" :class="{active:showBt}"/></n-button></div>
                    </div>
                </div>
                <div class="c-c-bottom" v-if="showBt">
                    <hr>
                    <div class="c-head">I consent to the use of the following cookies:</div>
                    <div class="c-inputs">
                        <div v-for="(c,i) in inputs" :key="i">
                            <div>
                                <input :name="c.text" type="checkbox" v-model="c.checked">
                                <div :class="{checked:c.checked}">
                                    <svg viewBox="0 0 24 24"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                </div>
                            </div>
                            <span>{{c.text}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {reactive, ref} from "vue";
import {NButton} from 'naive-ui'
import arrowIcon from '@/components/icons/arrow.vue'

const show = ref(!localStorage.getItem('cookie'))
const showBt = ref(false)

const inputs = reactive(['Necessary','Functionality','Analytics'].map(e=>({checked:true,text:e})))

const setCookie = () => {
    show.value = false
    localStorage.setItem('cookie','1')
}
</script>
<style scoped lang="scss">
hr{margin: 20px 0;}
.cookie{
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: rgb(23, 45, 62);
    .cookie-content{
        position: absolute;
        height: auto;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        >div{
            flex: 1 1 0;
            margin: 0 auto;
            max-width: 90vw;
            display: flex;
            flex-direction: column;
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }
    .c-head{
        font-weight: 600;
    }
    .c-list{
        display: flex;
        flex-wrap: wrap;
        >div{
            flex: 1 1 0;
            min-width: 50%;
            font-weight: 400;
            color: rgba(23, 45, 62, 0.6);
            font-size: 14px;
            line-height: 25px;
            &::before{
                content: "•";
                margin: 10px;
            }
            a:hover{text-decoration: underline;}
        }
    }
    .c-btns{
        margin-top: 20px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        >div{display: flex}
    }
    .c-btn{
        height: 44px;
        font-size: 15px;
        font-weight: 700!important;
        padding: 0 34px;
    }
    .c-arrow-icon{
        margin-left: 5px;
        transition: all 0.25s ease-in-out 0s;
        transform: rotate(0deg);
        &.active{transform: rotate(180deg);}
    }
    .c-inputs{
        margin-top: 16px;
        display: flex;
        >div{
            margin-right: 10px;
            >div{
                display: inline-block;
                vertical-align: middle;
                position: relative;
                line-height: 1;
                input{
                    overflow: hidden;
                    white-space: nowrap;
                    position: absolute;
                    inset: 0;
                    padding: 0;
                    margin: -1px;
                    border: 0;
                    opacity: 0;
                    cursor: pointer;
                }
                >div{
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border: 0.5px solid rgb(211, 178, 91);
                    border-radius: 3px;
                    transition: all 150ms ease 0s;
                    &.checked{background-color: rgb(211, 178, 91);}
                    svg{
                        stroke: white;
                        visibility: visible;
                        fill: none;
                        stroke-width: 2px;
                    }
                }
            }
            >span{margin: 10px;}
        }
    }
}
@media only screen and (min-width: 1200px){
    .cookie .cookie-content >div{max-width: 1200px}
}
@media only screen and (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
    .cookie .c-list{flex-direction: column;}
}
</style>