<template>
    <header :class="{'h-home':route.name==='home'}">
        <div class="h-c-div">
            <div class="h-left">
                <div class="h-left-al">
                    <div class="h-logo">
                        <router-link to="/"><img src="@img/logo.svg"></router-link>
                    </div>
                </div>
            </div>
            <div class="h-right">
                <div>
                    <router-link to="/faq" class="h-link">FAQ's</router-link>
                    <n-button class="h-btn" tag="a" href="/#homeForm" :bordered="false" type="primary" round>Check XAUT</n-button>
                </div>
            </div>
        </div>
        <div  class="h-c-m-div">
            <div class="h-left">
                <router-link to="/"><img src="@img/logo.svg"></router-link>
            </div>
            <div class="h-right">
                <button @click="show=!show">
                    <menu-icon v-if="!show"/>
                    <close-icon v-else/>
                </button>
            </div>
            <div class="mobile_header_content" :class="{active:show}">
                <div class="inner">
                    <ul>
                        <li><router-link to="/faq" class="h-link">FAQ's</router-link> </li>
                        <li style="background: none"><n-button class="h-btn" tag="a" href="/#homeForm" @click="show=false" block :bordered="false" type="primary" round>Check XAUT</n-button></li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>
<script setup>
import {NButton} from 'naive-ui'
import menuIcon from '@/components/icons/menu.vue'
import closeIcon from '@/components/icons/close.vue'
import {ref,watch} from "vue";
import {useRoute} from "vue-router";

const route = useRoute()
const show = ref(false)

watch(()=>route.name,()=>{
  show.value = false
})
</script>
<style scoped lang="scss">
header{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    &.h-home{background: linear-gradient(90deg, rgb(249, 247, 242) 50%, rgb(255, 255, 255) 50%);}
    .h-c-div{
        display: flex;
        flex: 1 1 0;
        height: 120px;
        .h-left,.h-right{
            display: flex;
            flex: 1 1 0;
            -webkit-box-align: center;
            align-items: center;
        }
        .h-left{
            -webkit-box-pack: end;
            justify-content: flex-end;
            a{
                margin: 0 20px;
                display: inline-block;
            }
            img{
                overflow-clip-margin: content-box;
                overflow: clip;
            }
        }
        .h-right{
            justify-content: center;
            >div{
                justify-content: space-between;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 430px;
                gap: 20px;
            }
        }
    }
    .h-link{
        color: rgba(23, 45, 62, 0.6);
        font-family: Inter;
        font-style: normal;
        text-decoration: none;
        text-transform: none;
        margin: 0 0 0 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        &:hover{color: rgb(23, 45, 62)}
    }
    .h-c-m-div{
        display: none;
        position: fixed;
        text-align: right;
        width: 100%;
        z-index: 5;
        top: 0;
        left: 0;
        height: 62px;
        padding: 0 8px;
        background-color: rgb(249, 247, 242);
        .mobile_header_content{
            visibility: hidden;
            position: fixed;
            overflow-y: hidden;
            inset: 0;
            text-align: left;
            display: flex;
            flex-direction: column;
            background-color: inherit;
            padding-top: 62px;
            transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
            &.active{
                visibility: visible;
                z-index: 6;
                width: 100%;
                height: auto;
                box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
            }
            .inner{
                overflow-y: scroll;
                display: flex;
                flex-direction: column;
                -webkit-box-pack: start;
                justify-content: flex-start;
                ul{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0;
                    margin: 0;
                    li{
                        list-style: none;
                        flex: 1 1 0;
                        display: flex;
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: start;
                        justify-content: flex-start;
                        width: 100%;
                        background-color: rgb(255, 255, 255);
                        padding: 20px;
                    }
                }
            }
        }
        .h-right{
            flex: 1 1 0;
            display: flex;
            -webkit-box-pack: end;
            justify-content: flex-end;
            z-index: 7;
            cursor: pointer;
            button{
                font-family: Inter;
                font-style: normal;
                text-decoration: none;
                text-transform: none;
                border: none;
                box-shadow: unset;
                cursor: pointer;
                background: transparent;
                margin: 0 8px 0 0;
                padding: 0;
            }
        }
        .h-left{
            flex: 1 1 0;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: end;
            justify-content: flex-end;
            z-index: 7;
            >*{
                width: 45vw;
                min-width: 45vw;
                display: flex;
            }
        }
    }
    .h-left-al{
        width: 45vw;
        min-width: 45vw
    }
    .h-logo{
        margin: 0;
    }
    .h-btn{
        height: 44px;
        padding: 0 34px;
        font-weight: 700!important;
    }
}
@media only screen and (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px){
    header {
        background: rgb(255, 255, 255);
        min-height: 62px;
        .h-c-div {display: none;}
        .h-c-m-div{display: flex;}
    }
}
@media only screen and (min-width: 1200px){
    header {
        .h-left-al{
            width: 600px;
            min-width: 600px;
        }
    }

}
</style>